import { FlagIcon } from "@heroicons/vue/20/solid";

export const whatIsTowerHunt = {
  order: 0,
  name: "What is Tower Hunt?",
  subcategory: "Intro to Tower Hunt",
  icon: FlagIcon,
  iconColor: "text-indigo-700",
  markdown: `# What is Tower Hunt?

  Tower Hunt is a personalized commercial real estate database that combines public and private information. By unifying key datapoints and offering a {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}pay-as-you-go approach{% /inlineRouterLink %}, Tower Hunt helps users add value while lowering costs, any day in any market.

  ## Working in Tower Hunt

  Tower Hunt delivers freedom and upside in all market conditions, making users and teams more efficient.

  ### Freedom

  Tower Hunt gives users the freedom to {% inlineRouterLink %}create{% /inlineRouterLink %}, {% inlineRouterLink %}edit{% /inlineRouterLink %}, or {% inlineRouterLink %}remove{% /inlineRouterLink %} any datapoint, meaning you are never stuck with someone else's bad or incomplete data. Changes are available immediately, and Tower Hunt {% inlineRouterLink articleId="understand-your-actions-in-tower-hunt" sectionName="Creating datapoints" %}automatically conceals private data{% /inlineRouterLink %} that is essential to market competition. You can personalize your experience with {% inlineRouterLink %}notes{% /inlineRouterLink %}, {% inlineRouterLink %}files{% /inlineRouterLink %}, {% inlineRouterLink articleId="stay-organized-with-tasks" %}tasks{% /inlineRouterLink %}, {% inlineRouterLink %}newsfeed{% /inlineRouterLink %}, and the {% inlineRouterLink %}map{% /inlineRouterLink %} to make the database your own. A powerful {% inlineRouterLink %}search bar{% /inlineRouterLink %} lets you move quickly across {% inlineRouterLink %}locations{% /inlineRouterLink %}, {% inlineRouterLink articleId="what-is-a-property?" %}properties{% /inlineRouterLink %}, {% inlineRouterLink %}players{% /inlineRouterLink %}, and notes.

  ### Upside

  Tower Hunt combines a {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}pay-as-you-go  approach{% /inlineRouterLink %} with a {% inlineRouterLink %}revenue sharing{% /inlineRouterLink %} model. {% inlineRouterLink %}Unlock{% /inlineRouterLink %} the specific datapoints you need &mdash; no matter how granular &mdash; and pay only when you access that information. This links your costs to the rhythm of your business, no matter where you focus or how the market shifts. Additionally, people who {% inlineRouterLink %}contribute to Tower Hunt's public layer{% /inlineRouterLink %} are able to earn revenue from this data when others access it. This lowers costs for everyone, and makes Tower Hunt a potential profit center for consistent contributors.

  ### Efficiency

  Tower Hunt makes market knowledge easy. The database handles complex real estate details so that you can see what's really happening in a market, a property, or a deal. Key market events bubble up to your {% inlineRouterLink %}personalized newsfeed{% /inlineRouterLink %}. And since real estate is as much about people as it is buildings, we make it simple to {% inlineRouterLink %}share specific datapoints{% /inlineRouterLink %} with anyone inside or outside your organization. Dedicated {% inlineRouterLink %}teams{% /inlineRouterLink %} let you bring together the right people and information &mdash; and even share earnings.

  ## Getting started with Tower Hunt

  ### Does your company already use Tower Hunt?

  If you're a new Tower Hunt user, learn how to {% inlineRouterLink articleId="getting-started-for-new-tower-hunt-users" %}get set up{% /inlineRouterLink %} and {% inlineRouterLink articleId="how-to-use-tower-hunt-your-quick-start-guide" %}get started{% /inlineRouterLink %}.

  ### Looking to set up Tower Hunt for your company?

  Learn more about {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}Tower Hunt's approach{% /inlineRouterLink %}, visit our {% inlineRouterLink articleId="getting-started-for-team-leaders" %}guide for team creators{% /inlineRouterLink %}, and dive into more {% inlineRouterLink categoryId="5" %}resources for administrators{% /inlineRouterLink %}.

  ### Ready to keep learning?

  Find detailed how-to articles and learning {% inlineRouterLink categoryId="6" %}tutorials{% /inlineRouterLink %} in our {% inlineRouterLink %}Help Center{% /inlineRouterLink %}.`,
};
